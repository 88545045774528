/**
 * @class InactiveLogout
 *
 * A reusable InactiveLogout indicator.
 *
 */
"use strict";

import EventEmitter from "eventemitter2";

class InactiveLogout extends EventEmitter {
   constructor() {
      super();
      this.app = null;
      this.InactiveLogoutInProgress = false;
   }

   /**
    * Early initialization. This can happen even before the auth token is
    * setup.
    *
    * @param {App} app
    *
    * @return {Promise}
    **/
   async init(app) {
      this.app = app;

      let idleTimer;

      function resetTimeout() {
         clearTimeout(idleTimer);
         idleTimer = timeout(300000);
      }

      window.onload = resetTimeout;
      document.onmousemove = resetTimeout;
      document.onkeypress = resetTimeout;
      document.ontouchstart = resetTimeout;  // For mobile touch events
      document.onclick = resetTimeout;

      document.addEventListener('visibilitychange', function() {
         if (document.hidden) {
           console.log('App is not in focus.');
           // Handle unfocused state
           timeout(60);
         } else {
            console.log('App is back in focus.');
            clearTimeout(idleTimer);
            idleTimer = timeout(300000);
           // Handle focused state
         }
       });
   }

   timeout(counter) {
      setTimeout(() => {
         if (document.hidden) {
            console.log('App is not in focus.');
            // Handle unfocused state
            window.location.reload(true);
          } else {
            console.log('App is back in focus.');
            // Handle focused state
            f7App.toast
               .create({
                  text: `<center><t data-cy="wip" >Locking app due to inactivity...</t></center>`,
                  position: "center",
               })
               .open();
            // window.location.reload(true);
          }
         // Ask if user is still active
      }, counter);
   }
}

export default new InactiveLogout();
