/*
 * local.js
 * provide any overrides to the standard configs here.
 * and don't check this file into any git repository.
 */
module.exports = {
   appbuilder: {
      maID: "SDC.id",
      networkType: "relay",
      tenantUUID: "admin",
      maxPacketSize: 900000, //1048576,
      maxJobAge: 1000 * 60 * 60 * 24 * 7, // 7 days before old packets are deleted
      // urlCoreServer: "http://localhost:83",
      urlCoreServer: "http://localhost:1337",
      // urlRelayServer: "https://analytics.appdevdesigns.net:1502"
      // urlRelayServer: "http://localhost:16005"
      // urlRelayServer: "http://localhost:83"
      urlRelayServer: "https://mcc3.digiserve.org",
   },
   backgroundTimeout: {
      enabled: true,
      duration: 9, // minutes
   },
   codepush: {
      keys: {
         // PRODUCTION
         ios: "32x3ycckilZDindv4uq9JvhS7l43c982aa70-c48b-46c2-97d8-90e6ec4bf746",
         android:
            "GSQKl1MXa-knqpOeXGME11BNOXBLc982aa70-c48b-46c2-97d8-90e6ec4bf746",
      },
   },
   countly: {
      url: "https://analytics.digiserve.org",
      appKey: "0dc5e51c8c5867932a4f90c86d82831fee0c4f1f",
   },
   onesignal: {
      appID: "f10695bb-52e4-4b78-a629-2dcbd92fc14e",
   },
   platform: {
      encryptedStorage: true,
      passwordProtected: true,
      shakeGesture: false,
   },
   sentryio: {
      dsn: "https://9df6fd4623934fadb4a9ee6bb6ec887f@sentry.io/1186956",
   },
};
